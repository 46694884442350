import React from "react";

function TrebleClef() {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width="40" height="40" viewBox="0 0 512 512"
             fill="currentColor"
        >
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M2411 4980 c-203 -55 -362 -215 -418 -421 -15 -55 -17 -113 -18 -394
0 -251 3 -335 13 -353 39 -68 140 -73 182 -9 12 18 16 88 20 362 l5 340 31 65
c77 161 238 242 411 206 175 -36 293 -177 293 -348 0 -156 -68 -320 -186 -446
-95 -102 -151 -147 -404 -317 -361 -243 -510 -368 -631 -528 -200 -265 -262
-632 -162 -952 109 -346 393 -616 751 -712 86 -24 116 -27 262 -27 146 0 176
3 262 27 469 125 799 546 798 1016 0 119 -19 208 -63 303 -79 171 -229 303
-412 365 -70 23 -96 27 -210 27 -117 0 -138 -3 -210 -28 -120 -41 -181 -80
-275 -175 -99 -100 -148 -186 -181 -321 -41 -164 -13 -358 75 -506 52 -89 138
-179 190 -199 41 -16 46 -16 81 1 43 20 70 72 60 112 -3 14 -35 57 -71 95
-113 123 -158 257 -136 405 38 240 227 404 467 404 304 0 510 -255 467 -576
-26 -197 -100 -343 -247 -492 -92 -92 -110 -105 -215 -157 -140 -68 -236 -91
-380 -91 -144 0 -240 23 -380 91 -105 52 -123 65 -215 157 -110 112 -167 199
-209 326 -74 224 -58 446 50 661 95 191 250 331 684 619 195 129 254 175 359
275 175 167 261 339 291 576 33 269 -120 508 -388 606 -95 35 -239 41 -341 13z"/>
                <path d="M4970 4016 c-19 -7 -73 -29 -120 -48 -267 -112 -612 -197 -1106 -272
-126 -19 -242 -40 -257 -45 -53 -20 -77 -104 -44 -154 33 -51 57 -53 295 -18
548 81 1006 198 1278 327 99 47 125 95 89 164 -25 48 -79 66 -135 46z"/>
                <path d="M4935 3164 c-199 -84 -311 -121 -557 -184 -162 -42 -189 -57 -204
-117 -10 -38 19 -90 61 -110 34 -16 40 -16 138 6 318 72 675 203 725 266 38
48 24 112 -30 145 -42 26 -60 25 -133 -6z"/>
                <path d="M905 3121 c-215 -49 -337 -82 -495 -133 -134 -44 -330 -121 -365
-144 -71 -46 -51 -160 32 -184 36 -11 47 -8 173 43 206 84 452 156 738 216 78
17 151 35 162 41 84 45 55 184 -40 195 -19 2 -111 -13 -205 -34z"/>
                <path d="M4890 2309 c-194 -83 -455 -160 -730 -214 -74 -15 -147 -33 -162 -41
-96 -50 -67 -194 40 -194 48 0 297 50 452 91 259 68 560 181 601 225 18 19 24
37 24 71 0 38 -5 49 -33 74 -44 39 -77 37 -192 -12z"/>
                <path d="M665 2224 c-197 -52 -400 -119 -535 -178 -80 -35 -98 -47 -114 -78
-38 -70 10 -148 92 -148 15 0 68 16 118 36 154 63 313 114 507 164 104 27 199
55 211 64 30 19 50 69 42 102 -4 15 -20 39 -36 55 -39 40 -77 37 -285 -17z"/>
                <path d="M1010 1465 c-330 -66 -644 -156 -858 -245 -121 -50 -147 -74 -147
-137 0 -38 5 -49 33 -74 44 -39 71 -37 199 15 225 91 487 165 842 237 166 33
199 43 222 65 48 46 41 120 -13 158 -32 23 -91 19 -278 -19z"/>
                <path d="M2050 1356 c-18 -6 -43 -24 -54 -39 -20 -28 -21 -39 -21 -360 1 -283
3 -341 18 -396 122 -451 680 -590 1000 -251 103 108 149 225 149 375 0 147
-68 262 -191 322 -48 24 -68 28 -141 28 -71 0 -94 -4 -140 -26 -79 -37 -125
-81 -161 -155 -69 -139 -39 -254 66 -254 59 0 97 43 110 122 12 78 102 128
171 94 98 -46 99 -214 3 -336 -161 -204 -476 -189 -613 28 -52 84 -56 113 -56
438 0 166 -3 315 -6 333 -8 37 -45 77 -78 83 -13 3 -38 0 -56 -6z"/>
            </g>
        </svg>
    );
}

export default TrebleClef;