export const ArrowDown = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20px"
            height="20px"
            fill="inherit">
            < path
                d="M 12 1.015625 C 11.606875 1.015625 11.213734 1.1588125 10.927734 1.4453125 L 1.4453125 10.927734 C 0.8533125 11.519734 0.8533125 12.481266 1.4453125 13.072266 L 10.927734 22.556641 C 11.213734 22.842641 11.595 23 12 23 C 12.405 23 12.785266 22.842641 13.072266 22.556641 L 22.556641 13.072266 C 23.148641 12.480266 23.148641 11.518734 22.556641 10.927734 L 13.072266 1.4453125 C 12.785766 1.1588125 12.393125 1.015625 12 1.015625 z M 12 3.1992188 L 20.800781 12 L 12 20.800781 L 3.1992188 12 L 12 3.1992188 z M 11 7 L 11 13 L 8 13 L 12 17 L 16 13 L 13 13 L 13 7 L 11 7 z"
            />
        </svg>
    )
}